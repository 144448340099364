import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { makeLink } from '@/utils/Link';
import useConditional from '@/hooks/useConditional';
import { LinkHandler } from '@/components/atoms';
import {
  IAnnouncementBar,
  IAnnouncementBarFields,
  IAnnouncementBarText,
} from '@/types/contentful/contentful';

SwiperCore.use([Autoplay, EffectFade]);

const Container = styled.div<IAnnouncementBarFields>`
  background: ${props =>
    props.background
      ? props.background
      : props.theme.announcementBar.backgroundColor};
  color: ${props =>
    props.textColor ? props.textColor : props.theme.announcementBar.color};
  text-align: center;
  overflow: hidden;
  padding: 8px;
  font-size: 14px;
  a {
    color: inherit;
    text-decoration: underline;
  }
` as React.ElementType;

const AnnouncementBar: React.FC<IAnnouncementBar> = props => {
  const {
    fields: { background, textColor, text },
  } = props;

  const { conditionalContent } = useConditional(text ?? null);

  const [showCont, setShowCont] = useState(false);
  const [realContent, setRealContent] = useState<IAnnouncementBarText[]>([]);

  const showCountdownbar = useSelector(
    (state: RootState) => state.countdownbarReducer.show
  );

  useEffect(() => {
    if (!showCountdownbar) {
      document.body.classList.add('annBar');
    } else {
      document.body.classList.remove('annBar');
    }
  }, [showCountdownbar]);

  useEffect(() => {
    if (conditionalContent && conditionalContent.length > 0) {
      const a = conditionalContent.filter(a => a.fields);
      setRealContent(a);
    }
  }, [conditionalContent]);

  useEffect(() => {
    if (realContent && realContent.length > 0) {
      setShowCont(true);
    }
  }, [realContent]);

  let singleContent = null;

  if (realContent && realContent.length === 1) {
    let url = '';

    if (realContent[0].fields && realContent[0].fields.linkedTo) {
      url = makeLink(
        realContent[0].fields.linkedTo.fields.type,
        realContent[0].fields.linkedTo.fields.href,
        null
      );
    }
    if (url) {
      singleContent = (
        <LinkHandler href={url} passHref source={'announcementBar'}>
          {realContent[0].fields && realContent[0].fields.heading
            ? realContent[0].fields.heading
            : null}
        </LinkHandler>
      );
    } else {
      singleContent = (
        <div>
          {realContent[0].fields && realContent[0].fields.heading
            ? realContent[0].fields.heading
            : null}
        </div>
      );
    }
  }

  const cont = showCont ? (
    <Container background={background} textColor={textColor}>
      {realContent && realContent.length > 1 ? (
        <Swiper
          autoplay={{ delay: 5000 }}
          loop
          speed={800}
          effect="fade"
          fadeEffect={{
            crossFade: true,
          }}>
          {realContent.map((t, i: number) => {
            let url = '';

            if (t.fields && t.fields.linkedTo) {
              url = makeLink(
                t.fields.linkedTo.fields.type,
                t.fields.linkedTo.fields.href,
                null
              );
            }
            return url !== '' ? (
              <SwiperSlide key={t.sys.id + i}>
                <p className="smalltitle">
                  <LinkHandler href={url} passHref source={'announcementBar'}>
                    {t.fields && t.fields.heading}
                  </LinkHandler>
                </p>
              </SwiperSlide>
            ) : (
              <SwiperSlide key={t.sys.id + i}>
                <p className="smalltitle">{t.fields && t.fields.heading}</p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        singleContent
      )}
    </Container>
  ) : null;

  return !showCountdownbar ? cont : null;
};

export default AnnouncementBar;
