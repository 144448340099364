import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import useMedium from './useMedium';

type ConditionalType = {
  fields?: object;
};

const useConditional = <T extends ConditionalType>(content: T[] | null) => {
  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const loadingCustomer = useSelector(
    (state: RootState) => state.userReducer.loadingCustomer
  );
  const medium = useMedium();

  const conditionalContent = useMemo(
    () =>
      content?.filter(c => {
        if (loadingCustomer) {
          return false;
        }
        if (!medium) {
          return false;
        }
        if (medium === 'MirtaPlus') {
          return (c.fields as any).mirtaPlusVisible;
        }

        if (!(c.fields as any)?.visibility) {
          return !(c.fields as any).mirtaPlusVisible;
        }

        if ((c.fields as any)?.visibility === 'logged_in' && isLogged) {
          return !(c.fields as any).mirtaPlusVisible;
        }

        if ((c.fields as any)?.visibility === 'anonimous' && !isLogged) {
          return !(c.fields as any).mirtaPlusVisible;
        }

        return false;
      }) ?? [],
    [isLogged, loadingCustomer, medium]
  );

  return { conditionalContent };
};

export default useConditional;
