import { useEffect, useState } from 'react';

const useMedium = () => {
  const [medium, setMedium] = useState<string | null>(null);

  const readMediumStorage = () => {
    if (!medium) {
      setMedium(window.sessionStorage.getItem('utm_medium'));
    }
  };

  const handleMediumStorageChanges = (e: any) => {
    if (e.key === 'utm_medium') {
      readMediumStorage();
    }
  };

  useEffect(() => {
    readMediumStorage();
    document.addEventListener('storage', handleMediumStorageChanges);
    return () => {
      document.removeEventListener('storage', handleMediumStorageChanges);
    };
  }, []);

  return medium;
};

export default useMedium;
